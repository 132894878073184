function createPromise() {
  let resolve, reject;

  const promise = new Promise(function (_resolve, _reject) {
    resolve = _resolve;
    reject = _reject;
  });

  promise.resolve = resolve;
  promise.reject = reject;
  return promise;
}

class ExrcBox {
  constructor(item, div) {
    this.item = item;
    this.div = div;
    this.selected = false;
    this.highlighted = false;
  }
}

class ExrcViewerOptions {
  constructor() {
    this.selectedBoxBackgroundColor = "rgba(253, 236, 0, 0.45)";
    this.selectAreaBorderColor = "blue";
    this.enableAnalyticLayer = true;
    this.enableSelectArea = true;
  }
}

class ExrcViewer {
  constructor() {
    this.options = new ExrcViewerOptions();
    this.data = [];
    this.isVisibleAnalyticLayer = true;
    this.eventBus = null;
    this.isRenderParams = true;
    this.isMouseDown = false;
    this.pdfAppOptions = window.PDFViewerApplicationOptions;
    this.pdfApp = window.PDFViewerApplication;
    this.pdfApp.initializedPromise.then(this.addEventHandlers.bind(this));
  }

  setOptions(options) {
    if (options) {
      const props = Object.getOwnPropertyNames(options);
      props.forEach(p => {
        this.options[p] = options[p];
      });
    }

    this.isVisibleAnalyticLayer = this.options.enableAnalyticLayer;
    this._setVisibleAnalyticLayer();

    console.log("### setOptions", this.options);
  }

  onSelectBox(item) {}

  onSelectArea(coord) {}

  onUnselectBox(item) {}

  onLayerRender(layer) {}

  onPageChange(page) { }

  onPdfOpened() {}

  open(url, data, options) {
    this.clearBoxes();
    this.setOptions(options);
    this.openPromise = createPromise();
    this.openPromise.then(() => {
      this.onPdfOpened();
    });
    this.pdfApp.initializedPromise.then(() => {
      this.data = this.convertDataIfNeeded(data);
      this.pdfApp.open(url);
    });
  }

  addBoxes(items) {
    items = this.convertDataIfNeeded(items);
    console.log("### addBoxes", items);

    items.forEach(item => {
      const layer = document.getElementById(
        "exrc-layer-" + (item.coordinates.page + 1)
      );
      if (layer) {
        const box = this._createBox(
          item,
          layer.clientWidth,
          layer.clientHeight
        );

        this.data.push(item);

        if (!this.boxes.has(item.coordinates.page)) {
          this.boxes.set(item.coordinates.page, []);
        }

        this.boxes.get(item.coordinates.page).push(box);
        layer.appendChild(box.div);
      }
    });
  }

  clearBoxes() {
    console.log("### clearBoxes");
    this.data = [];
    this.boxes = new Map();
    this.selectedIds = new Set();
    this.highlightedIds = new Set();
    const layers = document.getElementsByClassName("exrc-layer");
    if (layers?.length) {
      [].forEach.call(layers, l => l.remove());
    }
  }

  convertDataIfNeeded(data) {
    if (data?.length && data[0].attributes) {
      const convertedData = [];
      data.forEach(n => {
        if (n.attributes?.length) {
          const d = {};
          d.id = n.unifiedId;
          d.coordinates = n.attributes[0].coordinates;
          d.url = "https://data.exerica.com/unifiedId/" + n.unifiedId;
          d.description = n.value;
          d.color = n.color;
          convertedData.push(d);
        }
      });
      data = convertedData;
    }
    return data;
  }

  addEventHandlers() {
    this.eventBus = this.pdfApp.eventBus;

    this.pdfApp.eventBus.on("textlayerrendered", layer => {
      this.render(layer);
    });

    this.pdfApp.eventBus.on("pagechanging", page => {
      this.onPageChange(page);
    });

    this.pdfApp.eventBus.on("thumbnails_init", () => {
      this.setPagesInfo(this.pagesInfo);
    });

    this.pdfApp.eventBus.on("documentinit", () => {
      this.openPromise.resolve();
    });

    document.getElementById("analyticLayer").addEventListener("click", () => {
      this.isVisibleAnalyticLayer = !this.isVisibleAnalyticLayer;
      this._setVisibleAnalyticLayer();
    });

    this._setVisibleAnalyticLayer();
  }

  renderParams(layer) {
    console.log("### render", layer.pageNumber);

    const viewportWidth = layer.source.viewport.width;
    const viewportHeight = layer.source.viewport.height;
    const pageBoxes = [];

    if (this.data?.length) {
      this.data.forEach(item => {
        if (item.coordinates?.page === layer.pageNumber - 1) {
          const box = this._createBox(item, viewportWidth, viewportHeight);
          pageBoxes.push(box);
        }
      });
    }

    const fragment = document.createDocumentFragment();

    const layerDiv = document.createElement("div");
    layerDiv.classList.add("exrc-layer");
    layerDiv.id = "exrc-layer-" + layer.pageNumber;
    layerDiv.style.pointerEvents = "auto";
    layerDiv.style.width = viewportWidth + "px";
    layerDiv.style.height = viewportHeight + "px";
    if (!this.isVisibleAnalyticLayer) {
      layerDiv.style.pointerEvents = "none";
    }
    pageBoxes.forEach(box => layerDiv.appendChild(box.div));

    const selectionRect = document.createElement("div");
    selectionRect._area = { top: 0, left: 0, right: 0, bottom: 0 };
    selectionRect.classList.add("exrc-selection-rect");
    const rectColor = this.options.selectAreaBorderColor;
    selectionRect.style.boxShadow = `inset 2px -2px 0px 0px ${rectColor}, inset -2px 2px 0px 0px ${rectColor}`;
    layerDiv.appendChild(selectionRect);
    this._addSelectionRectEventHandlers(layer, layerDiv, selectionRect);

    fragment.appendChild(layerDiv);
    this.boxes.set(layer.pageNumber - 1, pageBoxes);
    layer.source.textLayerDiv.after(fragment);
  }

  render(layer) {
    if (this.isRenderParams) {
      this.renderParams(layer);
    } else {
      this.onLayerRender(layer);
    }
  }

  setPagesInfo(data) {
    this.pagesInfo = data;

    if (data?.length) {
      this.openPromise.then(() => {
        const pages = new Map();
        const thumbnails = this.pdfApp.pdfThumbnailViewer._thumbnails;

        if (!thumbnails[0].div.hasAttribute("iconsrendered")) {
          data.forEach(pageInfo => {
            if (!pages.has(pageInfo.page)) {
              const container = document.createElement("div");
              container.classList.add("exrc-page-info-container");
              container.style.maxWidth =
                thumbnails[pageInfo.page].div.clientWidth - 16 + "px";
              container.style.maxHeight =
                thumbnails[pageInfo.page].div.clientHeight - 16 + "px";

              thumbnails[pageInfo.page].div.appendChild(container);

              pages.set(pageInfo.page, container);
            }

            const info = document.createElement("div");
            info.classList.add("exrc-page-info");
            info.innerText = pageInfo.text;
            info.title = pageInfo.description;
            pages.get(pageInfo.page).appendChild(info);
          });
          thumbnails[0].div.setAttribute("iconsrendered", true);
        }
      });
    }
  }

  setPage(number) {
    this.openPromise.then(() => {
      this.pdfApp.page = number + 1;
    });
  }

  getPage() {
    return this.pdfApp.page - 1;
  }

  refresh() {
    this.openPromise.then(() => {
      this.pdfApp.pdfViewer.updatePages();
    });
  }

  setBoxes(data) {
    console.log("### setBoxes", data?.length);
    this.clearBoxes();
    this.openPromise.then(() => {
      this.data = this.convertDataIfNeeded(data);
      this.pdfApp.pdfViewer.updatePages();
    });
  }

  highlightBoxes(items) {
    this._clearHiglightedBoxes();

    if (items?.length) {
      items.forEach(item => {
        this.highlightedIds.add(item.id);
        this.boxes.get(item.coordinates.page)?.forEach(box => {
          if (box.item.id === item.id) {
            this._highlightBox(box);
          }
        });
      });
    }
  }

  selectBoxes(items, raiseEvents, scroll = true) {
    console.log("### selectBoxes", items[0].id + " - " + items[0].description);

    this._clearSelectedBoxes(raiseEvents);
    let firstItem = null;

    if (items?.length) {
      items.forEach(item => {
        if (!firstItem) {
          firstItem = item;
        } else {
          if (item.coordinates.page < firstItem.coordinates.page) {
            firstItem = item;
          } else if (
            item.coordinates.page === firstItem.coordinates.page &&
            item.coordinates.y < firstItem.coordinates.y) {
            firstItem = item;
          }
        }

        this.selectedIds.add(item.id);
        this.boxes.get(item.coordinates.page)?.forEach(box => {
          if (box.item.id === item.id) {
            console.log("### selectBoxes::this.boxes has item.id", item.id);
            this._selectBox(box, raiseEvents);
          }
        });
      });

      if (scroll) {
        this.openPromise.then(() => {
          const page = document.querySelector(
            `div.page[data-page-number="${firstItem.coordinates.page + 1}"]`
          );
          if (page) {
            const width = page.clientWidth;
            const height = page.clientHeight;
            const dummyBox = this._createBox(firstItem, width, height);
            dummyBox.div.style.backgroundColor = "transporent";
            dummyBox.div.style.pointerEvents = "none";
            this.setPage(firstItem.coordinates.page);

            setTimeout(() => {
              page.appendChild(dummyBox.div);
              dummyBox.div.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
              });
              dummyBox.div.remove();
            }, 0);
          }
        });
      }
    }
  }

  _createBox(item, viewportWidth, viewportHeight) {
    const box = new ExrcBox(item, document.createElement("div"));
    const gap = 1;
    const coor = item.coordinates;

    box.div.classList.add("exrc-box");
    box.div.draggable = true;
    box.div.style.pointerEvents = "auto";
    box.div.style.top = ((coor.y * viewportWidth) / viewportHeight) * 100 + "%";
    box.div.style.left = coor.x * 100 + "%";
    box.div.style.width = coor.width * 100 - (gap / viewportWidth) * 100 + "%";
    box.div.style.height =
      ((coor.height * viewportWidth) / viewportHeight) * 100 -
      (gap / viewportHeight) * 100 + "%";
    box.div.title = item.description;
    if (item.color) {
      box.div.style.backgroundColor = item.color;
    }
    box.div.id = "exrc-box-" + item.id;

    if (this.highlightedIds.has(item.id)) {
      this._highlightBox(box);
    }

    if (this.selectedIds.has(item.id)) {
      console.log("### render::selectedIds has item.id", item.id);
      this._selectBox(box, false);
    }

    this._addBoxEventListeners(box);

    return box;
  }

  _setVisibleAnalyticLayer() {
    const button = document.getElementById("analyticLayer");
    const layers = document.getElementsByClassName("exrc-layer");

    if (this.isVisibleAnalyticLayer) {
      button.classList.add("toggled");
      [].forEach.call(layers, l => (l.style.pointerEvents = "auto"));
    } else {
      button.classList.remove("toggled");
      [].forEach.call(layers, l => (l.style.pointerEvents = "none"));
    }
  }

  _addSelectionRectEventHandlers(layer, layerDiv, rect) {
    layerDiv.addEventListener("mousedown", e => {
      if (this.options.enableSelectArea) {
        let allowedElement = true;
        if (e.target?.id.startsWith("exrc-box")) {
          allowedElement = false;
        }

        if (allowedElement) {
          this.isMouseDown = true;
          this.selectionRectPage = layer.pageNumber;
          rect._area.left = e.offsetX;
          rect._area.top = e.offsetY;
          this._showSelectionRect(rect);
        }
      }
    });

    layerDiv.addEventListener("mousemove", e => {
      if (this.isMouseDown && layer.pageNumber === this.selectionRectPage) {
        rect._area.right = e.offsetX;
        rect._area.bottom = e.offsetY;
        this._showSelectionRect(rect);
      }
    });

    layerDiv.addEventListener("mouseup", e => {
      const viewportWidth = layer.source.viewport.width;
      const area = rect._area;

      if (
        this.isMouseDown &&
        area.right > area.left &&
        area.bottom > area.top
      ) {
        const coord = {};
        coord.page = layer.pageNumber - 1;
        coord.x = area.left / viewportWidth;
        coord.y = area.top / viewportWidth;
        coord.width = (area.right - area.left) / viewportWidth;
        coord.height = (area.bottom - area.top) / viewportWidth;
        this.onSelectArea(coord);
      }

      this.isMouseDown = false;
      rect._area.top = 0;
      rect._area.left = 0;
      rect._area.right = 0;
      rect._area.bottom = 0;
      this._showSelectionRect(rect);
    });
  }

  _showSelectionRect(rect) {
    rect.style.left = `${rect._area.left}px`;
    rect.style.top = `${rect._area.top}px`;
    rect.style.width = `${rect._area.right - rect._area.left}px`;
    rect.style.height = `${rect._area.bottom - rect._area.top}px`;
  }

  _clearHiglightedBoxes() {
    this.boxes?.forEach(page => {
      page.forEach(box => {
        if (box.highlighted) {
          this._unhighlightBox(box);
        }
      });
    });
    this.highlightedIds.clear();
  }

  _clearSelectedBoxes(raiseEvent) {
    this.boxes?.forEach(page => {
      page.forEach(box => {
        if (box.selected) {
          this._unselectBox(box, raiseEvent);
        }
      });
    });
    this.selectedIds.clear();
  }

  _selectBox(box, raiseEvent) {
    console.log("### selectBox", box.item.id + " - " + box.item.description);

    box.selected = true;
    box.div.classList.add("exrc-box-selected");
    box.defaultColor = box.div.style.backgroundColor;
    box.div.style.backgroundColor = this.options.selectedBoxBackgroundColor;
    if (raiseEvent) {
      this.onSelectBox(box.item);
    }
    this.selectedIds.add(box.item.id);
  }

  _unselectBox(box, raiseEvent) {
    if (raiseEvent) {
      this.onUnselectBox(box.item);
    }
    box.selected = false;
    box.div.classList.remove("exrc-box-selected");
    box.div.style.backgroundColor = box.defaultColor;
    this.selectedIds.delete(box.item.id);
  }

  _highlightBox(box) {
    box.highlighted = true;
    box.div.classList.add("exrc-box-highlighted");
    this.highlightedIds.add(box.item.id);
  }

  _unhighlightBox(box) {
    box.highlighted = false;
    box.div.classList.remove("exrc-box-highlighted");
    this.highlightedIds.delete(box.item.id);
  }

  _addBoxEventListeners(box) {
    box.div.addEventListener("click", event => {
      if (!box.selected) {
        this._clearSelectedBoxes(true);
        this._selectBox(box, true);
      }
    });

    box.div.addEventListener("dragstart", event => {
      this.isMouseDown = false;
      event.dataTransfer.setData("text/uri-list", box.item.url);
      event.dataTransfer.setData(
        "text/html",
        '<a href="' + box.item.url + '">' + box.item.description + "</a>"
      );
    });
  }
}

export { ExrcViewer };
