/* Copyright 2016 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AppOptions } from "./app_options.js";
import { ExrcViewer } from "./app_exrc.js";
import { PDFViewerApplication } from "./app.js";

/* eslint-disable-next-line no-unused-vars */
const pdfjsVersion =
  typeof PDFJSDev !== "undefined" ? PDFJSDev.eval("BUNDLE_VERSION") : void 0;
/* eslint-disable-next-line no-unused-vars */
const pdfjsBuild =
  typeof PDFJSDev !== "undefined" ? PDFJSDev.eval("BUNDLE_BUILD") : void 0;

window.PDFViewerApplication = PDFViewerApplication;
window.PDFViewerApplicationOptions = AppOptions;
window.ExrcViewer = new ExrcViewer();

// window.ExrcViewer.setOptions({
//   enableAnalyticLayer: false,
//   selectAreaBorderColor: "green",
// });

// window.ExrcViewer.onUnselectBox = item => {
//   console.log("Unselect", item);
// };
// window.ExrcViewer.onSelectBox = item => {
//   console.log("Select", item);
// };
// window.ExrcViewer.onSelectArea = coord => {
//   console.log("SelectArea", coord);
// }
// window.ExrcViewer.onPdfOpened = () => {
//   console.log("Pdf opened");
// }

// const selectedIds1 = [
//   {
//     id: "005FA922548B93A90001E25E450C004FC273065F2FCB01",
//     coordinates: { page: 12 },
//   },
//   {
//     id: "005FA922548B93A90001E25E450C007E96A50AF035CB01",
//     coordinates: { page: 12 },
//   },
//   {
//     id: "005FA922548B93A90001E25E450C00D4BCA50AFB27CB01",
//     coordinates: { page: 12 },
//   },
// ];

// const selectedIds11 = [
//   {
//     id: "005FA922548B93A90001E25E450C004FC273065F2FCB01",
//     coordinates: { page: 12 },
//   },
//   {
//     id: "005FA922548B93A90001E25E450C007E96A50AF035CB01",
//     coordinates: { page: 12 },
//   },
// ];

// const selectedIds2 = [
//   {
//     id: "005FA922548B93A90001E25E450D0075C6B6085574CB01",
//     coordinates: { page: 13 },
//   },
//   {
//     id: "005FA922548B93A90001E25E450D0075C6B6080C71CB01",
//     coordinates: { page: 13 },
//   },
// ];

// const pagesInfo = [
//   {
//     page: 0,
//     text: "FS",
//     description: "asdfas asdfasdfa asdfasdf asdfasdf",
//   },
//   {
//     page: 0,
//     text: "CF",
//     description: "asdfasd asdfasdf adsasdf",
//   },
//   {
//     page: 0,
//     text: "DP",
//     description: "asdfasd asdfasdf adsasdf",
//   },
//   {
//     page: 0,
//     text: "VB",
//     description: "asdfasd asdfasdf adsasdf",
//   },
//   {
//     page: 1,
//     text: "FS",
//     description: "asdfas asdfasdfa asdfasdf asdfasdf",
//   }
// ];

// async function open() {
//   const data = await getTestData("test_parameters.json");
//   window.ExrcViewer.open("test_report.pdf", null, {
//     enableAnalyticLayer: false,
//     enableSelectArea: true,
//   });
//   window.ExrcViewer.setBoxes(data);
//   //window.ExrcViewer.setPage(4);

//   window.ExrcViewer.setPagesInfo(pagesInfo);
//   //window.ExrcViewer.setPage(20);

//   setTimeout(() => {
//     //window.ExrcViewer.addBoxes(data.slice(11, 20));
//     //window.ExrcViewer.selectBoxes(data.slice(0, 2));
//     // const d = data.slice(0, 2000);
//     // for (let i = 0; i < 10; i++) {
//     //   d[i].color = "rgba(10, 10, 10, 0.3)";
//     // }
//     // for (let i = 10; i < 20; i++) {
//     //   d[i].color = "blue";
//     // }
//     //window.ExrcViewer.setBoxes(d);

//     const selectedBoxes = window.ExrcViewer.data.filter(
//       n =>
//       (
//         // n.id === "005FA922548B93A90001E25E450D0075C6B6085574CB01"
//         // ||
//         // n.id === "005FA922548B93A90001E25E450D0075C6B6080C71CB01"
//         // ||
//         n.id === "005FA922548B93A90001E25E4514000BD0B6080CCFCB01"
//         // ||
//         // n.id === "005FA922548B93A90001E25E450D00C4ADF0018525CB01"
//       )
//     );
//     console.log(selectedBoxes);

//     selectedBoxes.push(...selectedBoxes);

//     window.ExrcViewer.selectBoxes(selectedBoxes, false, true);
//     //window.ExrcViewer.highlightBoxes(selectedIds11);

//     // window.ExrcViewer.setOptions({
//     //   selectedBoxBackgroundColor: "yellow",
//     //   selectAreaBorderColor: "purple",
//     // });
//     //console.log(window.ExrcViewer.getPage());

//   }, 2000);
// }

// async function getTestData(fileName) {
//   const response = await fetch(fileName);
//   if (response.ok) {
//     const json = await response.json();
//     return json.data || json;
//   }
//   return [];
// }

// open();

if (typeof PDFJSDev !== "undefined" && PDFJSDev.test("CHROME")) {
  var defaultUrl; // eslint-disable-line no-var

  (function rewriteUrlClosure() {
    // Run this code outside DOMContentLoaded to make sure that the URL
    // is rewritten as soon as possible.
    const queryString = document.location.search.slice(1);
    const m = /(^|&)file=([^&]*)/.exec(queryString);
    defaultUrl = m ? decodeURIComponent(m[2]) : "";

    // Example: chrome-extension://.../http://example.com/file.pdf
    const humanReadableUrl = "/" + defaultUrl + location.hash;
    history.replaceState(history.state, "", humanReadableUrl);
    if (top === window) {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage("showPageAction");
    }
  })();
}

if (typeof PDFJSDev !== "undefined" && PDFJSDev.test("MOZCENTRAL")) {
  require("./firefoxcom.js");
  require("./firefox_print_service.js");
}
if (typeof PDFJSDev !== "undefined" && PDFJSDev.test("GENERIC")) {
  require("./genericcom.js");
}
if (typeof PDFJSDev !== "undefined" && PDFJSDev.test("CHROME")) {
  require("./chromecom.js");
}
if (typeof PDFJSDev !== "undefined" && PDFJSDev.test("CHROME || GENERIC")) {
  require("./pdf_print_service.js");
}

function getViewerConfiguration() {
  let errorWrapper = null;
  if (typeof PDFJSDev === "undefined" || !PDFJSDev.test("MOZCENTRAL")) {
    errorWrapper = {
      container: document.getElementById("errorWrapper"),
      errorMessage: document.getElementById("errorMessage"),
      closeButton: document.getElementById("errorClose"),
      errorMoreInfo: document.getElementById("errorMoreInfo"),
      moreInfoButton: document.getElementById("errorShowMore"),
      lessInfoButton: document.getElementById("errorShowLess"),
    };
  }

  return {
    appContainer: document.body,
    mainContainer: document.getElementById("viewerContainer"),
    viewerContainer: document.getElementById("viewer"),
    eventBus: null,
    toolbar: {
      container: document.getElementById("toolbarViewer"),
      numPages: document.getElementById("numPages"),
      pageNumber: document.getElementById("pageNumber"),
      scaleSelectContainer: document.getElementById("scaleSelectContainer"),
      scaleSelect: document.getElementById("scaleSelect"),
      customScaleOption: document.getElementById("customScaleOption"),
      previous: document.getElementById("previous"),
      next: document.getElementById("next"),
      zoomIn: document.getElementById("zoomIn"),
      zoomOut: document.getElementById("zoomOut"),
      viewFind: document.getElementById("viewFind"),
      openFile: document.getElementById("openFile"),
      print: document.getElementById("print"),
      presentationModeButton: document.getElementById("presentationMode"),
      download: document.getElementById("download"),
      viewBookmark: document.getElementById("viewBookmark"),
    },
    secondaryToolbar: {
      toolbar: document.getElementById("secondaryToolbar"),
      toggleButton: document.getElementById("secondaryToolbarToggle"),
      toolbarButtonContainer: document.getElementById(
        "secondaryToolbarButtonContainer"
      ),
      presentationModeButton: document.getElementById(
        "secondaryPresentationMode"
      ),
      openFileButton: document.getElementById("secondaryOpenFile"),
      printButton: document.getElementById("secondaryPrint"),
      downloadButton: document.getElementById("secondaryDownload"),
      viewBookmarkButton: document.getElementById("secondaryViewBookmark"),
      firstPageButton: document.getElementById("firstPage"),
      lastPageButton: document.getElementById("lastPage"),
      pageRotateCwButton: document.getElementById("pageRotateCw"),
      pageRotateCcwButton: document.getElementById("pageRotateCcw"),
      cursorSelectToolButton: document.getElementById("cursorSelectTool"),
      cursorHandToolButton: document.getElementById("cursorHandTool"),
      scrollVerticalButton: document.getElementById("scrollVertical"),
      scrollHorizontalButton: document.getElementById("scrollHorizontal"),
      scrollWrappedButton: document.getElementById("scrollWrapped"),
      spreadNoneButton: document.getElementById("spreadNone"),
      spreadOddButton: document.getElementById("spreadOdd"),
      spreadEvenButton: document.getElementById("spreadEven"),
      documentPropertiesButton: document.getElementById("documentProperties"),
    },
    sidebar: {
      // Divs (and sidebar button)
      outerContainer: document.getElementById("outerContainer"),
      viewerContainer: document.getElementById("viewerContainer"),
      toggleButton: document.getElementById("sidebarToggle"),
      // Buttons
      thumbnailButton: document.getElementById("viewThumbnail"),
      outlineButton: document.getElementById("viewOutline"),
      attachmentsButton: document.getElementById("viewAttachments"),
      layersButton: document.getElementById("viewLayers"),
      // Views
      thumbnailView: document.getElementById("thumbnailView"),
      outlineView: document.getElementById("outlineView"),
      attachmentsView: document.getElementById("attachmentsView"),
      layersView: document.getElementById("layersView"),
      // View-specific options
      outlineOptionsContainer: document.getElementById(
        "outlineOptionsContainer"
      ),
      currentOutlineItemButton: document.getElementById("currentOutlineItem"),
    },
    sidebarResizer: {
      outerContainer: document.getElementById("outerContainer"),
      resizer: document.getElementById("sidebarResizer"),
    },
    findBar: {
      bar: document.getElementById("findbar"),
      toggleButton: document.getElementById("viewFind"),
      findField: document.getElementById("findInput"),
      highlightAllCheckbox: document.getElementById("findHighlightAll"),
      caseSensitiveCheckbox: document.getElementById("findMatchCase"),
      entireWordCheckbox: document.getElementById("findEntireWord"),
      findMsg: document.getElementById("findMsg"),
      findResultsCount: document.getElementById("findResultsCount"),
      findPreviousButton: document.getElementById("findPrevious"),
      findNextButton: document.getElementById("findNext"),
    },
    passwordOverlay: {
      overlayName: "passwordOverlay",
      container: document.getElementById("passwordOverlay"),
      label: document.getElementById("passwordText"),
      input: document.getElementById("password"),
      submitButton: document.getElementById("passwordSubmit"),
      cancelButton: document.getElementById("passwordCancel"),
    },
    documentProperties: {
      overlayName: "documentPropertiesOverlay",
      container: document.getElementById("documentPropertiesOverlay"),
      closeButton: document.getElementById("documentPropertiesClose"),
      fields: {
        fileName: document.getElementById("fileNameField"),
        fileSize: document.getElementById("fileSizeField"),
        title: document.getElementById("titleField"),
        author: document.getElementById("authorField"),
        subject: document.getElementById("subjectField"),
        keywords: document.getElementById("keywordsField"),
        creationDate: document.getElementById("creationDateField"),
        modificationDate: document.getElementById("modificationDateField"),
        creator: document.getElementById("creatorField"),
        producer: document.getElementById("producerField"),
        version: document.getElementById("versionField"),
        pageCount: document.getElementById("pageCountField"),
        pageSize: document.getElementById("pageSizeField"),
        linearized: document.getElementById("linearizedField"),
      },
    },
    errorWrapper,
    printContainer: document.getElementById("printContainer"),
    openFileInputName: "fileInput",
    debuggerScriptPath: "./debugger.js",
  };
}

function webViewerLoad() {
  const config = getViewerConfiguration();
  if (typeof PDFJSDev === "undefined" || !PDFJSDev.test("PRODUCTION")) {
    Promise.all([
      import("pdfjs-web/genericcom.js"),
      import("pdfjs-web/pdf_print_service.js"),
    ]).then(function ([genericCom, pdfPrintService]) {
      PDFViewerApplication.run(config);
    });
  } else {
    if (typeof PDFJSDev !== "undefined" && PDFJSDev.test("CHROME")) {
      AppOptions.set("defaultUrl", defaultUrl);
    }

    if (typeof PDFJSDev !== "undefined" && PDFJSDev.test("GENERIC")) {
      // Give custom implementations of the default viewer a simpler way to
      // set various `AppOptions`, by dispatching an event once all viewer
      // files are loaded but *before* the viewer initialization has run.
      const event = document.createEvent("CustomEvent");
      event.initCustomEvent("webviewerloaded", true, true, {
        source: window,
      });
      try {
        // Attempt to dispatch the event at the embedding `document`,
        // in order to support cases where the viewer is embedded in
        // a *dynamically* created <iframe> element.
        parent.document.dispatchEvent(event);
      } catch (ex) {
        // The viewer could be in e.g. a cross-origin <iframe> element,
        // fallback to dispatching the event at the current `document`.
        console.error(`webviewerloaded: ${ex}`);
        document.dispatchEvent(event);
      }
    }

    PDFViewerApplication.run(config);
  }
}

// Block the "load" event until all pages are loaded, to ensure that printing
// works in Firefox; see https://bugzilla.mozilla.org/show_bug.cgi?id=1618553
if (document.blockUnblockOnload) {
  document.blockUnblockOnload(true);
}

if (
  document.readyState === "interactive" ||
  document.readyState === "complete"
) {
  webViewerLoad();
} else {
  document.addEventListener("DOMContentLoaded", webViewerLoad, true);
}

export {
  ExrcViewer,
  PDFViewerApplication,
  AppOptions as PDFViewerApplicationOptions,
};
